{
  "name": "bski",
  "version": "0.9.15",
  "author": "Oleg Mihailik",
  "license": "MIT",
  "description": "BlueSky firehose/WebSocket and CAR in plain JavaScript",
  "repository": "https://github.com/colds-ky/bski",
  "main": "index.cjs",
  "exports": {
    "import": "./index.js",
    "require": "./index.cjs"
  },
  "type": "module",
  "scripts": {
    "build": "esbuild --bundle index.js --tsconfig=tsconfig.json --analyze --format=iife --sourcemap --global-name=bski --outfile=index.cjs",
    "build-rollup": "rollup index.js --format=iife --sourcemap --name=bski --file=index.cjs",
    "test": "node tests/test.cjs"
  },
  "keywords": [
    "bluesky",
    "bsky",
    "bski",
    "atproto",
    "firehose",
    "car",
    "dag/cbor",
    "dag",
    "cbor"
  ],
  "devDependencies": {
    "esbuild": "^0.24.2",
    "rollup": "^4.32.1"
  },
  "dependencies": {
    "@atcute/bluesky": "^1.0.12",
    "@atcute/car": "^2.0.1"
  }
}
