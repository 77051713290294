{
  "name": "coldsky",
  "version": "0.2.89",
  "description": "Library and the app for BlueSky",
  "main": "libs.js",
  "module": "package/index.js",
  "scripts": {
    "build": "node build.js",
    "watch": "node build.js --watch",
    "start": "node build.js --serve",
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "keywords": [
    "bluesky",
    "coldsky",
    "atproto",
    "social media"
  ],
  "author": "Oleg Mihailik",
  "license": "not yet",
  "dependencies": {
    "@atproto/api": "*",
    "@ipld/car": "*",
    "cbor-x": "*"
  },
  "devDependencies": {
    "@babel/core": "*",
    "@emotion/react": "^11.11.4",
    "@emotion/styled": "^11.11.0",
    "@mui/icons-material": "^5.15.14",
    "@mui/material": "^5.15.14",
    "@rollup/plugin-babel": "*",
    "@rollup/plugin-commonjs": "*",
    "@rollup/plugin-json": "*",
    "@rollup/plugin-node-resolve": "*",
    "ag-grid-community": "^32.2.0",
    "ag-grid-react": "^32.2.0",
    "dexie": "^4.0.8",
    "eld": "^1.0.0",
    "esbuild": "*",
    "fuse.js": "^6.6.2",
    "marked": "^13.0.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-router-dom": "^6.22.3",
    "rollup": "*",
    "three": "^0.167.1",
    "troika-three-text": "^0.49.1"
  },
  "optionalDependencies": {
    "gpu.js": "^2.16.0"
  }
}
